import React from 'react'
import styled from 'styled-components'
import { useField } from 'formik'
import moment from 'moment'
import { useIntl } from 'react-intl'
import { makeStyles } from '@material-ui/core/styles'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { ContentType } from '../types/ATNTypes'
import { colors } from '../utils/theme'
import InputLabel from './InputLabel'

const useStyles = makeStyles({
  root: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: colors.blue,
    },
  },
  disabled: {
    backgroundColor: colors.lightGrey,
    '& .MuiInputBase-input.Mui-disabled': {
      color: `${colors.grey}`,
    },
  },
})

const InputContainer = styled.div`
  padding: 5px 0 4%;
`

interface DatePickerProps {
  name: string
  label?: string | ContentType
  required?: boolean
}

const DatePicker = (props: DatePickerProps) => {
  const classes = useStyles()
  const { name, label, required } = props
  const { formatMessage } = useIntl()
  const [
    { onBlur },
    { value, touched, error },
    { setValue, setError },
  ] = useField({
    name,
    validate: () => validateFormikDate(),
  })

  const validateFormikDate = () => {
    const requiredMessage = formatMessage({ id: 'error.requiredTextInput' })
    const invalidFormatMessage = 'Ungültiges Datumsformat'
    const invalidFutureMessage = 'Datum muss in der Vergangenheit liegen'
    if (value === 'invalid date') return invalidFormatMessage
    else if (required && !moment(value, 'YYYY-MM-DD').isValid())
      return requiredMessage
    else if (moment(value, 'YYYY-MM-DD').isAfter(moment()))
      return invalidFutureMessage
  }

  const handleChange = (dateValue: any, textValue: any) => {
    if (!textValue) return setValue(null)
    else if (!dateValue.isValid()) return setValue('invalid date')
    setValue(moment(dateValue).format('YYYY-MM-DD'))
    setError(undefined)
  }

  const isValidDate = (val: any) => {
    if (!val || val === 'invalid date') return false
    else if (!moment(val, 'YYYY-MM-DD').isValid()) return false
    return true
  }

  const formattedValue = isValidDate(value)
    ? moment.parseZone(value, 'YYYY-MM-DD')
    : null

  return (
    <InputContainer>
      <InputLabel label={label} required={required} />
      <KeyboardDatePicker
        name={name}
        className={classes.root}
        autoOk
        disableFuture
        variant="dialog"
        inputVariant="outlined"
        helperText={(touched && error) || ''}
        error={touched && !!error}
        format="DD.MM.YYYY"
        placeholder={moment().format('DD.MM.YYYY')}
        InputAdornmentProps={{ position: 'start' }}
        onChange={(dateValue: any, textValue: any) =>
          handleChange(dateValue, textValue)
        }
        onAccept={(dateValue) => {
          setValue(moment(dateValue).format('YYYY-MM-DD'))
          setError(undefined)
        }}
        onBlur={onBlur}
        value={formattedValue}
      />
    </InputContainer>
  )
}

export default DatePicker
